import React from "react";
import './terms.css'
import { useNavigate } from "react-router-dom";
export default function TermsAndConditions() {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleBackClick = () => {
      navigate(-1); // Go back to the previous page
    };
  return (
    <div class="terms-container">
         
      <h2>Terms and Conditions</h2>
      <p>
        These <strong>Terms and Conditions</strong> (“<strong>Terms</strong>”) govern the participation of the
        purchasers (“<strong>Purchaser/You</strong>”) in accessing a video message from Mr.
        Mahendra Singh Dhoni (“<strong>MSD</strong>”) after purchasing his exclusive signature
        collection product (“<strong>Product</strong>”) by scanning the Quick Response Code (“<strong>QR
        code</strong>”). By scanning the QR code, you agree to be bound by these <strong>Terms</strong>.
      </p>

      <h3>A. Access to Video Message:</h3>
      <p>
        1. Upon purchasing the Product, you will have the option to scan a QR
        code provided with the Product. <br />
        2. Scanning the QR code will direct you to a webpage where you will be
        required to enter your details specifically such as [●] to access and
        view your video message from MSD. <br />
        However, do note that providing the aforementioned information is
        optional and therefore, in the event that you do not wish to share the
        aforementioned information, you will not be able to access the video
        message.
      </p>

      <h3>B. Personal Information:</h3>
      <p>
        1. By entering your details to access the video message, you agree to
        provide accurate and current information as prompted. <br />
        2. The information collected may include but is not limited to: name,
        email address, mobile number, and any other details required for access
        to the video message.
      </p>

      <h3>C. Use of Personal Information:</h3>
      <p>
        1. Your personal information shall be used for the purpose of
        facilitating your access to the video message; marketing and promotional
        purposes; internal analysis and to improve our products and services,
        unless you opt out by specifically writing to [●]. <br />
        2. Your personal information will not be shared with third parties
        unless necessary for the administration of the access process or as
        required by law.
      </p>

      <h3>D. Liability:</h3>
      <p>
        We are not responsible for any technical malfunctions, human error, lost
        or delayed data transmission, omission, interruption, deletion, defect,
        or any other errors related to the scanning process or access to the
        video message.
      </p>

      <h3>E. Modification or Termination:</h3>
      <p>
        We reserve the right to modify, suspend, or terminate the QR code
        scanning feature and access to the video message at any time without
        prior notice, for any reason.
      </p>

      <h3>F. Governing Law:</h3>
      <p>
        These Terms are governed by the laws of India and the courts of Mumbai
        have sole and exclusive jurisdiction in relation to any dispute arising
        in relation to these Terms.
      </p>

      <h3>G. Conclusion:</h3>
      <p>
        These Terms set out that accessing the video message is optional after
        purchasing the Product and emphasizes data protection principles while
        providing clear terms for participation.
      </p>
      <button className="back-btn" onClick={handleBackClick}>Back</button>
    </div>
  );
}
