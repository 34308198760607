import { useEffect, useState } from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./images/logo.svg";
import regtext from "./images/reg-text.svg";
import usericon from "../pages/svgs/user.svg";
import emailicon from "./images/email.svg";
import blackEmailicon from "../pages/svgs/email-black.svg";
import "../App.css";
import userBlackIcon from "../pages/svgs/user-black.svg";
import mobileicon from "../pages/svgs/mobileNo.svg";
import mobileiconBlack from "../pages/svgs/mobileNo-black.svg";
import OTPVerifier from "../components/otpVerifier/OTPVerifier";
import icon from "./images/icon.svg";
import iconWhite from "./images/iconWhite.svg";
import closeIcon from "./images/ic-close.svg";

function Register() {
  const Baseurl = "https://api.msdhoni-menofplatinum.com";
  const [otp, setOtp] = useState("");
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animationStart, setAnimationStart] = useState(false);
  const [productCode, setproductCode] = useState("");
  const [resendTimer, setResendTimer] = useState(45);
  const [timerActive, setTimerActive] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
    const string = otpValues.join("");
    console.log(string);
    setOtp(string);
    document.getElementById("userregistrationError").textContent = "";
  }, [otpValues]);
  const nameRegex = /^(?!.*\s\s)[a-zA-Z\s]{3,20}$/;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const indianPhoneNumberRegex = /^[6789]\d{9}$/;

  useEffect(() => {
    let timer = setTimeout(() => setAnimationStart(true), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (timerActive) {
      interval = setInterval(() => {
        setResendTimer((prev) => {
          if (prev > 0) return prev - 1;
          setTimerActive(false);
          return 45;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerActive]);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errorPopupText, setErrorPopupText] = useState("")
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobilenum, setMobilenum] = useState("");
  const [isNameFilled, setIsNameFilled] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [isMobileNumFilled, setIsMobileNumFilled] = useState(false);

  const handleOtpVerification = async () => {
    const checkbox = document.getElementById("accepted");
    const nameInput = document.getElementById("nameinput");
    const emailInput = document.getElementById("emailinput");
    const mobileInput = document.getElementById("mobileinput");
    const nameVal = nameInput.value;
    const emailVal = emailInput.value;
    const mobVal = mobileInput.value;

    if (nameVal === "") {
      document.getElementById("nameError").textContent =
        "This field is required.";
    } else if (emailVal === "") {
      document.getElementById("emailerrorsmsg").textContent =
        "This field is required.";
    } else if (mobVal === "") {
      document.getElementById("nameError").textContent =
        "This field is required.";
    } else if (!checkbox.checked) {
      document.getElementById("checkboxerrorMsg").textContent =
        "You must accept the Terms & Conditions.";
    } else {
      document.getElementById("nameError").textContent = "";
      document.getElementById("emailerrorsmsg").textContent = "";
      document.getElementById("mobErrorMsg").textContent = "";
      document.getElementById("checkboxerrorMsg").textContent = "";
      setLoading(true);
      try {
        const lowercaseEmail = email.toLowerCase();
        const response = await axios.get(
          `${Baseurl}/api/get_otp/?email_id=${lowercaseEmail}`
        );
        console.log("OTP API Response:", response.data);
        if (response.data.status === "success") {
          console.log("User registered successfully");
          document.getElementById("userregistrationError").textContent = "";
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          localStorage.setItem("number", mobilenum);
          navigate("/verify");
          setClicked(true);
          setTimerActive(true);
          window.scroll(0, 0);
        } else {
          console.log("User registration failed");
          document.getElementById("userregistrationError").textContent =
            response.data.msg;
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const lowercaseEmail = email.toLowerCase();
      const response = await axios.get(
        `${Baseurl}/api/get_otp/?email_id=${lowercaseEmail}`
      );
      console.log("Resend OTP API Response:", response.data);

      if (response.data.status === "success") {
        console.log("OTP resent successfully");
        setTimerActive(true);
        setOtpValues(["", "", "", ""]);
        setOtp("");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleRegistration = async () => {
  //   setLoading(true);
  //   try {
  //     const lowercaseEmail = email.toLowerCase();
  //     const response = await axios.post(
  //       `${Baseurl}/api/create_user/`,
  //       {
  //         email_id: lowercaseEmail,
  //         email_otp: otp,
  //         name: name,
  //         mobile: mobilenum,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //     );

  //     console.log("Create API Response:", response.data);

  //     if (response.data.status === "success") {
  //       document.getElementById("userregistrationError").textContent = "";
  //       console.log("User registered successfully");
  //       navigate("/message");
  //     } else {
  //       console.log("User registration failed");
  //       document.getElementById("userregistrationError").textContent =
  //         response.data.msg;
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleRegistration = async () => {
    const checkbox = document.getElementById("accepted");
    const nameInput = document.getElementById("nameinput");
    const emailInput = document.getElementById("emailinput");
    const mobileInput = document.getElementById("mobileinput");
    const nameVal = nameInput.value;
    const emailVal = emailInput.value;
    const mobVal = mobileInput.value;

    if (nameVal === "") {
      document.getElementById("nameError").textContent =
        "This field is required.";
    } else if (emailVal === "") {
      document.getElementById("emailerrorsmsg").textContent =
        "This field is required.";
    } else if (mobVal === "") {
      document.getElementById("nameError").textContent =
        "This field is required.";
    }
        else if(!productCode){
          document.getElementById("codeError").textContent="This field is required."
        
    } else if (!checkbox.checked) {
      document.getElementById("checkboxerrorMsg").textContent =
        "You must accept the Terms & Conditions.";
    } else {
      document.getElementById("nameError").textContent = "";
      document.getElementById("emailerrorsmsg").textContent = "";
      document.getElementById("mobErrorMsg").textContent = "";
      document.getElementById("checkboxerrorMsg").textContent = "";
     
    setLoading(true);
    try {
      const lowercaseEmail = email.toLowerCase();
      const response = await axios.post(
        `${Baseurl}/api/create_user/`,
        {
          email_id: lowercaseEmail,
          code: productCode,
          name: name,
          mobile: mobilenum,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      console.log("Create API Response:", response.data);

      if (response.data.status === "success") {
        document.getElementById("userregistrationError").textContent = "";
        console.log("User registered successfully");
        localStorage.setItem("email", email)
        localStorage.setItem("code",productCode)
        navigate("/verify");
      } else {
        console.log("User registration failed", response.data);
        if(response.data.msg.includes("Code")||response.data.msg.includes("code")){
          setIsErrorPopupVisible(true)
          setErrorPopupText(response.data.msg)
        }
        else
        document.getElementById("userregistrationError").textContent =
          response.data.msg;
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }}
  };


  const [cellColors, setCellColors] = useState(Array(4).fill("#202020"));
  console.log(cellColors);

  const handleOnChange = (value, index) => {
    setOtp(value);
    const newColors = [...cellColors];
    newColors[index] = !value ? "white" : "#202020";
    setCellColors(newColors);
  };

  const handleNameChange = (e) => {
    document.getElementById("nameError").textContent = "";
    setName(e.target.value.trimStart());
    setIsNameFilled(e.target.value.trim() !== "");
  };

  const handleEmailChange = (e) => {
    document.getElementById("emailerrorsmsg").textContent = "";

    document.getElementById("userregistrationError").textContent = "";
    setEmail(e.target.value);
    setIsEmailFilled(e.target.value !== "");
  };

  const handleMobileChange = (e) => {
    document.getElementById("mobErrorMsg").textContent = "";
    setMobilenum(e.target.value);
    setIsMobileNumFilled(e.target.value.trim() !== "");
  };
  const handleUniqueCodeChange = (e) => {
    document.getElementById("codeError").textContent = "";
    setproductCode(e.target.value.trim());
    // setIsMobileNumFilled(e.target.value.trim() !== "");
  };

  return (
    <div
      id="regContainer"
      style={{
        backgroundColor: "#202020",
        paddingTop: "20px",
      }}
      className="bg-no-repeat bg-cover w-full md:rounded-xl text-white px-5 py-8 overflow-y-scroll md:overflow-y-scroll relative scrollbar-hide custom-height"
    >
      {isErrorPopupVisible && (
        <div className="errorPopup">
          <img src={closeIcon} alt="" onClick={()=>setIsErrorPopupVisible(false)} />
          <span className="errorPopupHeader">Please try again</span>
          <span className="errorPopupText">
            {errorPopupText}
          </span>
        </div>
      )}
      <img
        className="w-[117px] md:w-[px]  object-contain mx-auto logo"
        src={logo}
        alt="Logo"
      />
      <img
        className={`mx-auto my-5 ${
          animationStart
            ? " delay-500 duration-500 ease-in-out"
            : "scale-0 opacity-0 -translate-y-10"
        } regText`}
        src={regtext}
        alt="Logo"
      />
      {clicked ? (
        <p
          style={{
            margin: "auto",
            fontFamily: "AvenirLTStd65Medium, sans-serif",
            fontSize: clicked ? "18px" : "",
          }}
          className={`font- text-[14px] leading-[17px] font-[350px] text-center w-[90%] welcomeText`}
        >
          Register
        </p>
      ) : (
        <p
          style={{
            margin: "auto",
            fontFamily: "AvenirLTStd65Medium, sans-serif",
            fontSize: clicked ? "18px" : "",
          }}
          className={`font- text-[13px] leading-[17px] font-[350px] text-center w-[90%]${
            animationStart
              ? " delay-500 duration-500 ease-in-out"
              : "scale-0 opacity-0 -translate-y-10"
          } welcomeText`}
        >
          Please enter your details to receive a <br /> personalized message
          from the legend itself.
        </p>
      )}

      <p
        id="userregistrationError"
        style={{ textAlign: "center" }}
        className="text-red-500 mt-4"
      ></p>

      {clicked ? (
        <div className="flex flex-col items-center gap-4 mt-9">
          <small className="text-sm font-light text-center">
            Enter the OTP sent to your email address.
          </small>
          <OTPVerifier otpValues={otpValues} setOtpValues={setOtpValues} />
          <Button
            onClick={handleRegistration}
            className={`mt-12 ${
              animationStart
                ? " delay-500 duration-500 ease-out"
                : " opacity-0 translate-y-10 "
            }`}
            title="Submit"
            disabled={otp.length !== 4}
          />
          <button
            onClick={handleResendOtp}
            className="mt-4 text-sm text-blue-400 underline"
            disabled={timerActive || loading}
          >
            {timerActive ? `Resend OTP in ${resendTimer}s` : "Resend OTP"}
          </button>
        </div>
      ) : (
        <div className="flex flex-col gap-2 md:gap-2 mt-7 formSection">
          <div
            id="inputWrapper"
            className={
              animationStart
                ? " delay-500 duration-500 ease-in-out"
                : " opacity-0 -translate-y-10"
            }
          >
            <div
              id="myInput"
              className={`${isNameFilled ? "bg-[#EAEAEA]" : "bg-[#202020]"}`}
              // }   w-full px-3 py-[14.5px] tracking-[0.06em] text-center flex items-center gradient-input rounded-md gap-2   ${
              //   animationStart
              //     ? " delay-500 duration-500 ease-in-out"
              //     : " opacity-0 -translate-y-10"
              // }`}
            >
              <img
                src={!isNameFilled ? usericon : userBlackIcon}
                alt="Person Icon"
              />

              <input
                onChange={handleNameChange}
                id="nameinput"
                value={name}
                className={` ${
                  !isNameFilled ? "caret-white" : "caret-black"
                }   placeholder:text-#E3E3E3 text-black gap-2 bg-transparent text-sm  font-[350] leading-[19.6px]  outline-none flex-1`}
                type="text"
                placeholder="Name"
                required
                onBlur={(e) => {
                  setName(e.target.value);
                  // document.body.style.zoom = '100%'
                  document.body.style.transform = "scale(1)";
                  document.body.style.transformOrigin = "0 0";
                  if (e.target.value.trim() === "") {
                    document.getElementById("nameError").textContent =
                      "This field is required.";
                  } else if (!nameRegex.test(e.target.value)) {
                    document.getElementById("nameError").textContent =
                      "Please enter a valid name, a minimum of 3 characters and maximum of 20 characters is required.";
                  } else {
                    document.getElementById("nameError").textContent = "";
                  }
                }}
              />
            </div>
          </div>
          <div>
            <span id="nameError" className="text-red-500"></span>
          </div>

          <div
            id="inputWrapper"
            className={
              animationStart
                ? " delay-500 duration-500 ease-in-out"
                : " opacity-0 -translate-y-10"
            }
          >
            <div
              id="myInput"
              className={`${isEmailFilled ? "bg-[#EAEAEA]" : "bg-[#202020]"}`}
              // }  w-full px-3 py-[14.5px] flex items-center gradient-input rounded-md gap-2 ${
              //   animationStart
              //     ? " delay-500 duration-500 ease-in-out"
              //     : " opacity-0 -translate-y-10"
              // }`}
            >
              <img
                src={!isEmailFilled ? emailicon : blackEmailicon}
                alt="Email Icon"
              />

              <input
                // onChange={(e) => setIsEmailFilled(e.target.value !== "")}
                onChange={handleEmailChange}
                id="emailinput"
                className={`${
                  !isEmailFilled ? "caret-white" : "caret-black"
                }     placeholder:text-#E3E3E3 text-black gap-2 bg-transparent text-sm outline-none flex-1`}
                type="email"
                placeholder="E-mail ID"
                onBlur={(e) => {
                  setEmail(e.target.value);

                  if (e.target.value === "") {
                    document.getElementById("emailerrorsmsg").textContent =
                      "This field is required.";
                  } else if (!emailRegex.test(e.target.value.toLowerCase())) {
                    document.getElementById("emailerrorsmsg").textContent =
                      "Please enter a valid email address";
                  } else {
                    document.getElementById("emailerrorsmsg").textContent = "";
                  }
                }}
                required
              />
            </div>
          </div>
          <div>
            <span id="emailerrorsmsg" className="text-red-500"></span>
          </div>
          <div
            id="inputWrapper"
            className={
              animationStart
                ? " delay-500 duration-500 ease-in-out"
                : " opacity-0 -translate-y-10"
            }
          >
            <div
              id="myInput"
              className={`${
                isMobileNumFilled ? "bg-[#EAEAEA]" : "bg-[#202020]"
              }`}
            >
              <img
                src={!isMobileNumFilled ? mobileicon : mobileiconBlack}
                alt=" Mobile Icon"
              />

              <input
                onChange={handleMobileChange}
                id="mobileinput"
                className={`${
                  !isMobileNumFilled ? "caret-white" : "caret-black"
                }     placeholder:text-#E3E3E3 text-black gap-2 bg-transparent text-sm outline-none flex-1`}
                type="tel"
                maxLength={10}
                minLength={10}
                placeholder="Mobile number"
                onBlur={(e) => {
                  setMobilenum(e.target.value);

                  if (e.target.value === "") {
                    document.getElementById("mobErrorMsg").textContent =
                      "This field is required.";
                  } else if (!indianPhoneNumberRegex.test(e.target.value)) {
                    document.getElementById("mobErrorMsg").textContent =
                      "Please enter your valid 10 digit phone number.";
                  } else {
                    document.getElementById("mobErrorMsg").textContent = "";
                  }
                }}
                required
              />
            </div>
          </div>
          <div>
            {" "}
            <span id="mobErrorMsg" className="text-red-500"></span>
         
          </div>
          <div
              id="inputWrapper"
              className={
                animationStart
                  ? " delay-500 duration-500 ease-in-out"
                  : " opacity-0 -translate-y-10"
              }
            >
              <div
                id="myInput"
                className={`${
                  productCode.length > 0 ? "bg-[#EAEAEA]" : "bg-[#202020]"
                }`}
                // }  w-full px-3 py-[14.5px] flex items-center gradient-input rounded-md gap-2 ${
                //   animationStart
                //     ? " delay-500 duration-500 ease-in-out"
                //     : " opacity-0 -translate-y-10"
                // }`}
              >
                <img
                  src={productCode.length > 0 ? icon : iconWhite}
                  alt=" Mobile Icon"
                />
                <input
                  onChange={handleUniqueCodeChange}
                  id="mobileinput"
                  className={`${
                    !productCode.length > 0 ? "caret-white" : "caret-black"
                  }     placeholder:text-#E3E3E3 text-black gap-2 bg-transparent text-sm outline-none flex-1`}
                  type="tel"
                  maxLength={10}
                  minLength={10}
                  placeholder="Serial No*"
                  onBlur={(e) => {
                    // setMobilenum(e.target.value);

                    if (e.target.value === "") {
                      document.getElementById("codeError").textContent =
                        "This field is required.";
                    } else if ((e.target.value).length!==10) {
                      document.getElementById("codeError").textContent =
                        "Please enter a valid 10-digit serial number.";
                    } else {
                      document.getElementById("codeError").textContent = "";
                    }
                  }}
                  required
                />
                
              </div>
             

            </div>
            <span
            style={{fontSize:"9px", color:'#FFFFFF'}}
            className={`${
              animationStart
              ? " delay-500 duration-500 ease-in-out"
              : " opacity-0 -translate-y-10"
          }`}
            >*Check the back of your Pt950 assurance card for the 10-digit serial number.</span>
            <span id="codeError" className="text-red-500"></span>
          <div
            className={`flex text-xs gap-2 items-center mt-2 ${
              animationStart
                ? " delay-500 duration-500 ease-in-out"
                : " opacity-0 -translate-y-10"
            }`}
          >
            <input
              className="bg-transparent border rounded-md "
              type="checkbox"
              id="accepted"
              name="accepted"
              value="True"
              required
            />
            <label
              className="text-xs font-[350] leading-[14.64px] text-left font-AvenirLTStd65Medium"
              htmlFor="accepted"
            >
              I accept the{" "}
              
            </label>
            <span className="underline font-bold" style={{marginLeft:'-5px', cursor:"pointer"}} onClick={()=>{navigate("/terms&conditions")}}>Terms & Conditions.</span>
          </div>

          <div>
            <span id="checkboxerrorMsg" className="text-red-500"></span>
          </div>

          <Button
            onClick={handleRegistration}
            className={`mt-5  ${
              animationStart
                ? " delay-500 duration-500 ease-out"
                : " opacity-0 translate-y-10 "
            }`}
            title="Register"
            disabled={loading}
          />
        </div>
      )}

      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader">
            <button
              type="button"
              className="bg-blue-400 p-4 rounded-full flex items-center"
              disabled
            >
              <svg
                className="animate-spin h-5 w-5 text-white"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 0116 0"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
