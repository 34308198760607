import React from "react";

function Button({ title, className, onClick ,disabled }) {
  return (
    <button
    style={disabled?{cursor:'not-allowed'}:{}}
    disabled={disabled}
      onClick={onClick}
      className={`text-black leading-[19.8px] tracking-[0.04em] text-center w-full bg-gradient-to-b from-[#DBDBDB] to-[#656565] p-3 py-[14.5px] border border-white rounded-md text-lg font-semibold font-avenir ${
        disabled
          ? "bg-gray-400 cursor-not-allowed opacity-50"  // Disabled styles
          : "bg-gradient-to-b from-[#DBDBDB] to-[#656565]"  // Normal styles
      } ${className}`}
    >
      {title}
    </button>
  );
}
//linear-gradient(180deg, #DBDBDB 0%, #656565 100%);
export default Button;
