// import React from "react";
// import styles from "./Splash.module.css";
// import logo from "./images/MOP logo - white 2.svg";
// import backgroundImg from "./images/Splash screen(large mobile).png";
// import { useNavigate } from "react-router-dom";

// export default function Splash() {
//   const navigate = useNavigate()
//   return (
//     <div className={styles.container}>
//       {/* <img
//         style={{
//           width: "100%",
//         }}
//         src={backgroundImg}
//         alt=""
//       /> */}
//       <div className={styles.contentSectionNew}>
//         <img src={logo} alt="" />
//         <span className={styles.primaryText}>
//         Thank you for purchasing the<br /> MS Dhoni Signature Edition.
//         </span>
//         <span className={styles.secondaryText}>
//         Here’s a memento especially crafted for you.
//         </span>
//         <button onClick={() => navigate("/register")} className={styles.getStartedBtn}>Get Started</button>
//       </div>
//     </div>
//   );
// }

import React from "react";
// import styles from "./Splash.module.css";
import logo from "./images/MOP logo - white 2.svg";
import backgroundImg from "./images/Splash screen(large mobile).png";
import { useNavigate } from "react-router-dom";
import dhoni from './images/dhoniWObcg.png'
import vector from './images/Vector (5).png'
import styles from './newcss.module.css'

export default function Splash() {
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
    <div className={styles.circle}></div>
    <div className={styles.innerContainer}>
      <img src={dhoni} alt="" />
    </div>
    <div className={styles.vector}>
      <img src={vector} alt="" />
    </div>
    <div className={styles.contentSectionNew}>
      <img src={logo} alt="" />
      <span className={styles.primaryText}>
        Thank you for purchasing the<br /> MS Dhoni Signature Edition.
      </span>
      <span className={styles.secondaryText}>
        Here’s a memento especially crafted for you.
      </span>
      <button onClick={() => navigate("/register")}>Get Started</button>
    </div>
  </div>
  );
}
