
import { useEffect } from "react";
import Splash2 from "./Splash2";
import { useNavigate } from "react-router-dom";

function Message() {


  return (
    <>
        <Splash2 />
    </>
  );
}

export default Message;
