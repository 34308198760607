import { useEffect, useState } from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./images/logo.svg";
import regtext from "./images/reg-text.svg";
import "../App.css";

import OTPVerifier from "../components/otpVerifier/OTPVerifier";

function OTP() {
  const Baseurl = "https://api.msdhoni-menofplatinum.com";
  const [otp, setOtp] = useState("");
  const [productCode, setProductCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [animationStart, setAnimationStart] = useState(false);
  const [resendTimer, setResendTimer] = useState(45);
  const [timerActive, setTimerActive] = useState(true);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const navigate = useNavigate();

  useEffect(() => {
    const string = otpValues.join("");
    console.log(string);
    setOtp(string);
    document.getElementById("userregistrationError").textContent = "";
  }, [otpValues]);

  useEffect(() => {
    window.scroll(0, 0);
    let userEmail = localStorage.getItem("email");
    let code = localStorage.getItem("code");
    console.log("Print", code, userEmail);
    if (userEmail && code) {
      setEmail(userEmail);
      setProductCode(code);
    } else {
      navigate("/register");
    }
    let timer = setTimeout(() => setAnimationStart(true), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (timerActive) {
      interval = setInterval(() => {
        setResendTimer((prev) => {
          if (prev > 0) return prev - 1;
          setTimerActive(false);
          return 45;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerActive]);

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      //http://api.msdhoni-menofplatinum.com/api/resend_otp/?code=1234&email_id=mahesh@apprikart.com
      const lowercaseEmail = email.toLowerCase();
      const response = await axios.get(
        `${Baseurl}/api/resend_otp/?code=${productCode}&email_id=${lowercaseEmail}`
      );
      console.log("Resend OTP API Response:", response.data);

      if (response.data.status === "success") {
        console.log("OTP resent successfully");
        setTimerActive(true);
        setOtpValues(["", "", "", ""]);
        setOtp("");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRegistration = async () => {
    const lowercaseEmail = email.toLowerCase();
    console.log(
      `${Baseurl}/api/validate_otp/?email_id=${lowercaseEmail}&otp=${otp}&code=${productCode}`
    );
    setLoading(true);
    //https://api.msdhoni-menofplatinum.com/api/validate_otp/?email_id=mahesh@apprikart.com&otp=1234
    try {
      const response = await axios.get(
        `${Baseurl}/api/validate_otp/?email_id=${lowercaseEmail}&otp=${otp}&code=${productCode}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log("Create API Response:", response.data);
      if (response.data.status === "success") {
        document.getElementById("userregistrationError").textContent = "";
        console.log("User registered successfully");
        localStorage.clear();
        navigate("/message");
      } else {
        console.log("User registration failed");
        document.getElementById("userregistrationError").textContent =
          "Invalid OTP";
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      id="regContainer"
      style={{
        backgroundColor: "#202020",
        paddingTop: "20px",
      }}
      className="bg-no-repeat bg-cover w-full h-full md:rounded-xl text-white px-5 py-8 overflow-y-scroll md:overflow-y-auto relative scrollbar-hide custom-height"
    >
      <img
        className="w-[117px] md:w-[px]  object-contain mx-auto logo"
        src={logo}
        alt="Logo"
      />
      <img
        className={`mx-auto my-7 ${
          animationStart
            ? " delay-500 duration-500 ease-in-out"
            : "scale-0 opacity-0 -translate-y-10"
        } regText`}
        src={regtext}
        alt="Logo"
      />
      <p
        style={{
          margin: "auto",
          fontFamily: "AvenirLTStd65Medium, sans-serif",
          fontSize: "18px",
        }}
        className={`font- text-[14px] leading-[17px] font-[350px] text-center w-[90%]`}
      >
        Register
      </p>
      <p
        id="userregistrationError"
        style={{ textAlign: "center" }}
        className="text-red-500 mt-4"
      ></p>
      <div className="flex flex-col items-center gap-4 mt-9">
        <small className="text-sm font-light text-center">
          Enter the OTP sent to your email address.
        </small>
        <OTPVerifier otpValues={otpValues} setOtpValues={setOtpValues} />
        <Button
          onClick={handleRegistration}
          disabled={otp.length !== 4}
          className={`mt-12 ${
            animationStart
              ? " delay-500 duration-500 ease-out"
              : " opacity-0 translate-y-10 "
          }`}
          title="Submit"
        />
        <button
          onClick={handleResendOtp}
          className={`mt-4 text-sm text-blue-400 underline
             ${animationStart
              ? " delay-500 duration-500 ease-out"
              : " opacity-0 translate-y-10 "
          }
            `}
          disabled={timerActive || loading}
        >
          {timerActive ? `Resend OTP in ${resendTimer}s` : "Resend OTP"}
        </button>
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader">
            <button
              type="button"
              className="bg-blue-400 p-4 rounded-full flex items-center"
              disabled
            >
              <svg
                className="animate-spin h-5 w-5 text-white"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 0116 0"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default OTP;
