// import React, { useEffect } from "react";
// import styles from "./Thankyou.module.css";
// import logo from "./images/MOP logo - white 2.svg";
// import backgroundImg from './images/blur-dhoni.png'

// export default function Splash2() {
//   useEffect(()=>{window.scroll(0,0)},[])
//   return (
//     <div className={styles.container}
//     style={{
//     }}
//     >
//       {/* <img style={{
//         width:'100%'
//       }} src={backgroundImg} alt="" /> */}
//        <div className={styles.contentSectionNew}>
//          <img src={logo} alt="" />
//            <span className={styles.primaryText}>Thank you for being a part of the <br/> Men of Platinum family.</span>
//            <span  className={styles.secondaryText}>
//              Your personalized message from our Man of  <br/> Platinum, <strong>MS Dhoni</strong>, is on
//              the way & will be  <br/> delivered to your mailbox in 15 minutes!
//            </span>
//          </div>
//     </div>
//   );
// }

import React from "react";
import logo from "./images/MOP logo - white 2.svg";
import dhoni from "./images/thank-you-page-cutout.png";
import vector from "./images/Vector (5).png";
import styles from "./newThankyouCss.module.css";

export default function Splash2() {
  return (
    <div className={styles.container}>
      <div className={styles.circle}></div>
      <div className={styles.innerContainer}>
        <img src={dhoni} alt="" />
      </div>
      <div className={styles.vector}>
        <img src={vector} alt="" />
      </div>
      <div className={styles.contentSectionNew}>
        <img src={logo} alt="" />
        <span className={styles.primaryText}>
          Thank you for being a part of the <br /> Men of Platinum family.
        </span>
        <span className={styles.secondaryText}>
          Your personalized message from our Man of <br /> Platinum, MS Dhoni,
          is on the way & will be <br /> delivered to your mailbox in 15
          minutes!
        </span>
      </div>
    </div>
  );
}
